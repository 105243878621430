import { application } from "./application";

import ClockController from "./clock_controller";
application.register("clock", ClockController);

import ConsentManagerController from "./consent_manager_controller";
application.register("consent-manager", ConsentManagerController);

import ContactFormController from "./contact_form_controller";
application.register("contact-form", ContactFormController);

import CookieBannerController from "./cookie_banner_controller";
application.register("cookie-banner", CookieBannerController);

import OpeningHoursController from "./opening_hours_controller";
application.register("opening-hours", OpeningHoursController);

import SlideshowController from "./slideshow_controller";
application.register("slideshow", SlideshowController);

import StepsController from "./steps_controller";
application.register("steps", StepsController);

import TrackingController from "./tracking_controller";
application.register("tracking", TrackingController);

import VideoController from "./video_controller";
application.register("video", VideoController);
