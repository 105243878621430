import { Controller } from "@hotwired/stimulus";

/**
 * VideoController
 * ----------------------------------------------------------------------------
 * The {VideoController} enhances the video element by handling clicks on the image.
 *
 */

export default class extends Controller {
  static classes = [ "hidden" ];
  static targets = [ "template" ];

  play(event) {
    event.preventDefault();

    // Hide the image and the play button
    this.element.querySelector(".rlx-image").classList.add(this.hiddenClass);
    this.element.querySelector(".rlx-btn").classList.add(this.hiddenClass);

    // Show the iframe and start
    this.element.querySelector("iframe").classList.remove(this.hiddenClass);
  }
}
