import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "state", "hours", "day" ];

  connect() {
    this.setHours();
    this.setState();
  }

  setHours() {
    const message = this.isOpenToday ? `${this.startsAt} - ${this.endsAt}` : "";
    this.hoursTarget.innerText = message;
  }

  setState() {
    this.stateTarget.innerText = this.isOpenToday ? "Open Today" : "Closed Today";;
  }

  get dayOfWeek() {
    return new Date().getDay();
  }

  get todayTarget() {
    return this.dayTargets.find((_day, idx) => idx == this.dayOfWeek);
  }

  get isOpenToday() {
    return this.startsAt && this.endsAt;
  }

  get startsAt() {
    return this.todayTarget.dataset.startsAt;
  }

  get endsAt() {
    return this.todayTarget.dataset.endsAt;
  }
}
