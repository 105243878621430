import { Controller } from "@hotwired/stimulus";
import { track } from "../utilities/tracking";

/**
 * TrackingController
 * ----------------------------------------------------------------------------
 * The {TrackingController} handles tracking Rolex related events on the site.
 * However, it's only tracking events whenever the visitor gave their consent to
 * track them.
 *
 * @example basic usage of the controller
 *
 *  <div data-controller="tracking" data-tracking-event-name-value="contactCall">
 *    <a data-action="tracking#track" href="#" title="Get in touch with us">Contact us</a>
 *  </div>
 *
 */

export default class extends Controller {
  static values = {
    eventName: String
  };

  track(_event) {
    track(this.eventNameValue);
  }
}
