import { Controller } from "@hotwired/stimulus";
import A11YSlider from "a11y-slider";

export default class extends Controller {
  static targets = [ "slides", "next", "prev" ];
  static values = { visibleItems: String };

  connect() {
    this.slider = new A11YSlider(this.slidesTarget, {
      arrows: false,
      infinite: true,
      prevArrow: this.prevTarget,
      nextArrow: this.nextTarget,
      slidesToShow: this.slidesToShow,
      responsive: {
        768: {
          arrows: true,
        }
      },
      customPaging: function(index, _a11ySlider) {
        return `
          <button type="button">
            <span class="sr-only">Move slider to item #${index}</span>
          </button>
        `;
      }
    });
  }

  get slidesToShow() {
    return getComputedStyle(this.element).getPropertyValue(`--${this.visibleItemsValue}`);
  }
}
