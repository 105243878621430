import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = [ "hidden" ];
  static targets = [ "step" ];
  static values = { step: String };

  connect() {
    this.showActiveStep();
  }

  next() {
    const nextStep = this.stepTargets[this.currentStepIndex + 1];
    this.goToStep(nextStep);
  }

  prev() {
    const prevStep = this.stepTargets[this.currentStepIndex - 1];
    this.goToStep(prevStep);
  }

  goToStep(newStep) {
    const { stepName } = newStep.dataset;

    // The {beforeEvent} allows other Javascript to cancel the proceeding to the
    //  next step in the process.
    const beforeEvent = this._broadcastStepChange(
      "steps:before",
      { newStep: newStep, currentStep: this.currentStepEl }
    );

    if (!beforeEvent.defaultPrevented) {
      this.stepValue = stepName;
      this.showActiveStep();
    }
  }

  showActiveStep() {
    this.stepTargets.forEach(stepEl => {
      stepEl.classList.toggle(this.hiddenClass, stepEl !== this.currentStepEl);
    });
  }

  get currentStepEl() {
    return this.stepTargets.find(stepEl => stepEl.dataset.stepName == this.stepValue);
  }

  get currentStepIndex() {
    return this.stepTargets.indexOf(this.currentStepEl);
  }

  _broadcastStepChange(eventName, data) {
    const event = new CustomEvent(eventName, { bubbles: true, cancelable: true, detail: data });
    this.element.dispatchEvent(event);
    return event;
  }
}
